import { useCallback, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useAppSelector } from "../app/hooks";
import { ComflowIframeMessage } from "../components/Comflow/dataTypes";
import useLogOut from "../features/session/hooks/useLogOut";
import { selectSessionId } from "../features/session/sessionSelectors";
import { selectSystemSettings } from "../features/system/systemSettingsSelectors";
import { EMBEDDED_PAGE } from "../utils/constants";
import { useEmbedded } from "./useEmbedded";

const useIdle = (timeout: number | undefined) => {
  const { logOut } = useLogOut();
  const { hasEmbedded } = useEmbedded();

  const userLoggedIn = !!useAppSelector(selectSessionId);
  const systemSettings = useAppSelector(selectSystemSettings);

  const handleOnIdle = () => {
    if (userLoggedIn) {
      logOut();
    }
  };

  const idleTimer = useIdleTimer({
    crossTab: true,
    onIdle: handleOnIdle,
    timeout: timeout,
    startManually: true,
  });

  const eventListener = useCallback(
    (event: MessageEvent) => {
      //Events are fired from Comflow, defined in ca_main.
      const message: ComflowIframeMessage = event.data;

      if (message.source !== EMBEDDED_PAGE) return;

      idleTimer.reset();
    },
    [idleTimer],
  );

  useEffect(() => {
    if (hasEmbedded && systemSettings?.useTimeOut) {
      //Event listener listening for events from embedded iframe to reset timer if received
      window.addEventListener("message", eventListener);
    }

    // Return cleanup function to remove event listener when component unmounts or dependencies change
    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, [eventListener, hasEmbedded, idleTimer, systemSettings?.useTimeOut]);

  return {
    idleTimer,
  };
};

export default useIdle;
