import { GridColDef, GridPaginationModel } from "@mui/x-data-grid-premium";
import { useMemo, useState } from "react";
import { CUSTOMER_ORDER_LINE_FIELDS, GetCustomerOrderLinesFilter } from "../../../features/customerOrderLine/dataTypes";
import useGetCustomerOrderLines from "../../../features/customerOrderLine/hooks/useGetCustomerOrderLines";
import { FULFILLED_UPDATE_METHOD } from "../../../features/dataTypes";
import { BINDER } from "../../../features/utils/SqlBinders";
import { SQL_OPERATOR } from "../../../features/utils/SqlOperators";
import { translate } from "../../../preferences/localization";
import DataGrid from "../../DataGrid/DataGrid";
import { DEFAULT_PAGINATION_MODEL } from "../../DataGrid/config/DataGridConfig";

const standardColumnDef = {
  editable: false,
  width: 150,
  sortable: false,
};

function CUSTOMER_ORDER_LINE_COLUMNS(): GridColDef[] {
  return [
    {
      field: CUSTOMER_ORDER_LINE_FIELDS.LINE_NUMBER,
      headerName: translate("LINE_NBR"),
      ...standardColumnDef,
    },
    {
      field: CUSTOMER_ORDER_LINE_FIELDS.ITEM_ID,
      headerName: translate("ITEM"),
      ...standardColumnDef,
    },
    {
      field: CUSTOMER_ORDER_LINE_FIELDS.ITEM_DESCRIPTION,
      headerName: translate("DESCRIPTION"),
      ...standardColumnDef,
    },
    {
      field: CUSTOMER_ORDER_LINE_FIELDS.ORDER_QUANTITY,
      headerName: translate("ORDERED"),
      ...standardColumnDef,
    },
    {
      field: CUSTOMER_ORDER_LINE_FIELDS.RESERVED_QUANTITY,
      headerName: translate("RESERVED"),
      ...standardColumnDef,
    },
    {
      field: CUSTOMER_ORDER_LINE_FIELDS.DELIVERED_QUANTITY,
      headerName: translate("DELIVERED"),
      ...standardColumnDef,
    },
  ];
}

const CustomerOrderLinesDataGrid = ({ customerOrderId }: { customerOrderId: string }) => {
  const serverMode = "server";

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);

  const filter = useMemo<GetCustomerOrderLinesFilter | undefined>(() => {
    const customerOrderIds = customerOrderId.split("¤");

    if (customerOrderIds.length !== 2) return undefined;

    return {
      paginationOptions: {
        all: false,
        amount: paginationModel.pageSize,
        from: paginationModel.page * paginationModel.pageSize,
      },
      orderByFields: [],
      whereGroups: [
        {
          binder: BINDER.AND,
          fields: [
            {
              field: CUSTOMER_ORDER_LINE_FIELDS.CLIENT,
              operator: SQL_OPERATOR.EQ,
              value: customerOrderIds[0] ?? "",
            },
            {
              field: CUSTOMER_ORDER_LINE_FIELDS.ORDER_NUMBER,
              operator: SQL_OPERATOR.EQ,
              value: customerOrderIds[1] ?? "",
            },
          ],
        },
      ],
    };
  }, [paginationModel, customerOrderId]);

  const { customerOrderLines, count, isLoading } = useGetCustomerOrderLines({
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.UPSERT_MANY,
    filter,
  });

  return (
    <DataGrid
      loading={isLoading}
      autoHeight
      disableColumnFilter
      pagination
      paginationMode={serverMode}
      paginationModel={paginationModel}
      pageSizeOptions={[paginationModel.pageSize]}
      onPaginationModelChange={setPaginationModel}
      rows={customerOrderLines}
      rowCount={count}
      columns={CUSTOMER_ORDER_LINE_COLUMNS()}
      disableRowSelectionOnClick
    />
  );
};

export default CustomerOrderLinesDataGrid;
