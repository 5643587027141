import { Link } from "react-router-dom";

export default function PnTileMyloc(props: { label: string; illustration: string; content: string; link: string }) {
  return (
    <Link to={props.link}>
      <pn-tile label={props.label} illustration={props.illustration}>
        <p>{props.content}</p>
      </pn-tile>
    </Link>
  );
}
