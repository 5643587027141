import classNames from "classnames";
import PropTypes from "prop-types";
import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router";
import { ValueOf } from "../../../utils/dataTypes";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import styles from "./Page.module.scss";

const Page = ({
  title,
  description,
  children,
  customCssClass,
  customHeaderCssClass,
  layout,
  spaceBetween,
  isLoading,
  loadingTitle,
  showRefreshButton = false,
}: {
  title?: string;
  description?: string;
  children?: ReactNode;
  customCssClass?: string;
  customHeaderCssClass?: string;
  layout?: ValueOf<typeof LAYOUT>;
  spaceBetween?: boolean;
  isLoading?: boolean;
  loadingTitle?: string;
  showRefreshButton?: boolean;
}) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isEmbedded = Boolean(location.pathname.match(/^\/embedded\//));

  return (
    <section
      className={classNames(
        styles.page,
        layout,
        spaceBetween ? styles.spaceBetween : undefined,
        isEmbedded ? styles.embedded : undefined,
        customCssClass,
      )}
    >
      <div className={classNames(styles.headerSection, !title && styles.noTitle, customHeaderCssClass)}>
        {title && (
          <div className={showRefreshButton ? styles.displayRefreshButton : undefined}>
            <h2 className={styles.title}>
              {title} {description && <span className={styles.description}> - {description}</span>}
            </h2>
          </div>
        )}
      </div>

      {isLoading ? <LoadingSpinner title={loadingTitle} /> : children}
    </section>
  );
};

export const LAYOUT = {
  ONE_TO_ONE: styles.oneToOne,
} as const;

Page.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  layout: PropTypes.oneOf(Object.values(LAYOUT)),
  spaceBetween: PropTypes.bool,
  customCssClass: PropTypes.string,
  customHeaderCssClass: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingTitle: PropTypes.string,
  showRefreshButton: PropTypes.bool,
  children: PropTypes.node,
};

export default Page;
