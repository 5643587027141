import { HeaderOptions, Method, Request, RestOptions, sendRequest, URL } from "@myloc/myloc-utils";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { api } from "../../../config/settings";
import { setErrors } from "../../../features/dialog/dialogSlice";
import {
  selectComflowSessionId,
  selectCurrentGoodsOwnerId,
  selectSessionId,
} from "../../../features/session/sessionSelectors";
import { resetComflowSessionId, setComflowSessionId } from "../../../features/session/sessionSlice";
import { useEmbedded } from "../../../hooks/useEmbedded";
import { useSearchParams } from "../../../hooks/useSearchParams";
import useTranslate from "../../../language/useTranslate";
import { EMBEDDED_PAGE } from "../../../utils/constants";
import pages from "../../../utils/pages";
import { ComflowIframeMessage } from "../dataTypes";

const CONTENT_ACTION = "contentAction";
const SCROLL_ACTION = "scrollAction";
const LOADING_COMPLETED = "loadingCompleted";
const HIDE_SPINNER_COMPLETED = "hideSpinnerCompleted";

export const MYG_ACTIONS = {
  SELECT_GOODS_OWNER: "selectGoodsOwner",
};

const PORTAL_URL = process.env.REACT_APP_WEBAPP + "/portal/";
const REFRESH_CONTENT = "refreshContent";

const useComflow = ({ startTask, mygAction }: { startTask?: string; mygAction?: string }) => {
  const [response, setResponse] = useState("");
  const [forceRefresh, setForceRefresh] = useState(false);

  const refRefreshToken = useRef("");
  const translate = useTranslate();
  const virtualSessionId = useAppSelector(selectSessionId);

  const comflowSessionId = useAppSelector(selectComflowSessionId);
  const currentGoodsOwnerId = useAppSelector(selectCurrentGoodsOwnerId);

  const dispatch = useAppDispatch();
  const refCurrentTask = useRef("");
  const refIsFetchingComflowContent = useRef(false);

  const history = useHistory();
  const searchParams = useSearchParams();

  const { hasEmbedded } = useEmbedded();

  const responseReceived = !!response;

  const refreshToken = searchParams.get(REFRESH_CONTENT);

  useEffect(() => {
    //If refresh token has changed, activate force refresh, and store current token

    if (!refreshToken) {
      history.push({ search: "?" + REFRESH_CONTENT + "=" + uuidv4() });

      return;
    }

    if (refRefreshToken.current !== refreshToken) {
      refRefreshToken.current = refreshToken;
      setForceRefresh(true);
    }
  }, [forceRefresh, history, refRefreshToken, refreshToken, searchParams]);

  const closeComflowTabs = useCallback(
    async (_comflowSessionId: string) => {
      const closeTabsUrl = api.comflow.closeAllComflowActiveTasks(_comflowSessionId);

      const request = new Request(closeTabsUrl, Method.POST);
      const restOptions = new RestOptions();

      restOptions.headerOptions = new HeaderOptions();

      if (virtualSessionId) restOptions.headerOptions.setHeader("SessionId", virtualSessionId);

      sendRequest(request, {}, restOptions);
    },
    [virtualSessionId],
  );

  const showErrorRedirectToHome = useCallback(
    (errorMessage: string) => {
      dispatch(setErrors({ errors: [{ title: errorMessage, code: errorMessage }] }));
      refIsFetchingComflowContent.current = false;
      history.push(pages.HOME);
    },
    [dispatch, history],
  );

  const resolveComflowSessionIdFromComflowContent = useCallback((html: string) => {
    const sessionIndex = html.indexOf("sessionId");
    const startIndex = html.substring(sessionIndex).indexOf("'") + sessionIndex;
    const endIndex = html.substring(startIndex + 1).indexOf("'") + startIndex;
    const _comflowSessionId = html.substring(startIndex + 1, endIndex + 1);

    return _comflowSessionId;
  }, []);

  const dispatchComflowSessionIdFromComflowContent = useCallback(
    (_comflowSessionId: string) => {
      if (comflowSessionId !== _comflowSessionId) {
        dispatch(setComflowSessionId(_comflowSessionId));
      }
    },
    [comflowSessionId, dispatch],
  );

  const comflowLogin = useCallback(async () => {
    try {
      const url = new URL(PORTAL_URL);

      url.addParameter("myg-action", "login");

      const request = new Request(url, Method.GET);
      const restOptions = new RestOptions();

      restOptions.headerOptions = new HeaderOptions();

      if (virtualSessionId) restOptions.headerOptions.setHeader("SessionId", virtualSessionId);

      const response = await sendRequest(request, {}, restOptions);

      const html = response.data;

      if (typeof html !== "string") return null;

      const _comflowSessionId = resolveComflowSessionIdFromComflowContent(html);

      return _comflowSessionId ? _comflowSessionId : null;
    } catch (e) {
      //Just return to try again

      return null;
    }
  }, [resolveComflowSessionIdFromComflowContent, virtualSessionId]);

  const preFetchToForceNewSession = useCallback(async () => {
    const url = new URL(PORTAL_URL);
    const request = new Request(url, Method.GET);
    const restOptions = new RestOptions();

    restOptions.headerOptions = new HeaderOptions();
    restOptions.headerOptions.setHeader("forceNewSessionId", "forceNewSessionId");

    if (virtualSessionId) restOptions.headerOptions.setHeader("SessionId", virtualSessionId);

    const response = await sendRequest(request, {}, restOptions);

    const html = response.data;

    if (typeof html !== "string") return null;

    if (html.indexOf("Comflow login page") > 0) {
      return null;
    }

    const _comflowSessionId = resolveComflowSessionIdFromComflowContent(html);

    return _comflowSessionId;
  }, [resolveComflowSessionIdFromComflowContent, virtualSessionId]);

  const fetchComflowContent = useCallback(
    async _comflowSessionId => {
      const url = new URL(PORTAL_URL);

      if (startTask) url.addParameter("_startTask", startTask);

      url.addParameter("sessionId", _comflowSessionId);

      if (currentGoodsOwnerId) url.addParameter("clientId", currentGoodsOwnerId);
      if (mygAction) url.addParameter("myg-action", mygAction);

      const request = new Request(url, Method.GET);
      const restOptions = new RestOptions();

      restOptions.headerOptions = new HeaderOptions();

      if (virtualSessionId) restOptions.headerOptions.setHeader("SessionId", virtualSessionId);

      const response = await sendRequest(request, {}, restOptions);

      const html = response.data;

      if (typeof html !== "string") return null;

      if (html.indexOf("resetpassword") > 0) {
        return null;
      }

      return html;
    },
    [currentGoodsOwnerId, mygAction, startTask, virtualSessionId],
  );

  const isValidHtml = (html: string) => {
    //The error is just a message inside the html code. The response is still 200
    const isError = html.includes("500") && html.includes("Oops") && html.includes("Error");

    return !isError;
  };

  const loadComflowContent = useCallback(
    async (_comflowSessionId?: string | null) => {
      if (!_comflowSessionId) {
        _comflowSessionId = await preFetchToForceNewSession();
      }

      if (_comflowSessionId === null) {
        return null;
      }

      const html = await fetchComflowContent(_comflowSessionId);

      if (html === null) {
        return null;
      }

      if (!isValidHtml(html)) {
        throw new Error(translate("SERVER_COULDNT_PROCESS"));
      }

      setResponse(html);

      _comflowSessionId = resolveComflowSessionIdFromComflowContent(html);

      if (_comflowSessionId && startTask) {
        closeComflowTabs(_comflowSessionId);
      }

      return _comflowSessionId;
    },
    [
      closeComflowTabs,
      fetchComflowContent,
      preFetchToForceNewSession,
      resolveComflowSessionIdFromComflowContent,
      startTask,
      translate,
    ],
  );

  const doRetrieveComflowContent = useCallback(async () => {
    //Use comflowSessionId from storage if exists

    let _comflowSessionId = comflowSessionId ?? null;

    //Try to load comflow content
    _comflowSessionId = await loadComflowContent(_comflowSessionId);

    if (_comflowSessionId === null) {
      //No comflow session, login first

      _comflowSessionId = await comflowLogin();

      //Now load comflow content, if sessionId is received - and update sessionId from received content
      if (_comflowSessionId) {
        _comflowSessionId = await loadComflowContent(_comflowSessionId);
      }
    }

    if (!_comflowSessionId) {
      throw new Error("COMFLOW LOGIN FAILED");
    }

    //Everything OK, dispatch the sessionId received from comflow
    dispatchComflowSessionIdFromComflowContent(_comflowSessionId);
  }, [comflowLogin, comflowSessionId, dispatchComflowSessionIdFromComflowContent, loadComflowContent]);

  const retrieveComflowContent = useCallback(async () => {
    try {
      await doRetrieveComflowContent();
    } catch (e) {
      if (!(e instanceof Error)) return;

      //Probably session mixup, try again - new Comflow login will occur!
      try {
        await doRetrieveComflowContent();
      } catch (e) {
        if (!(e instanceof Error)) return;

        //Reset comflowSessionId to prevent further consequential errors - will force a new preFetch next time
        dispatch(resetComflowSessionId());

        showErrorRedirectToHome(e.message);
      }
    } finally {
      refIsFetchingComflowContent.current = false;
    }
  }, [dispatch, doRetrieveComflowContent, showErrorRedirectToHome]);

  useEffect(() => {
    if (!currentGoodsOwnerId || !refreshToken) return;

    if (!hasEmbedded) {
      showErrorRedirectToHome(translate("EMBEDDED_PATH_INCORRECT"));

      return;
    }

    if (
      (!refIsFetchingComflowContent.current && refCurrentTask.current !== startTask) ||
      refCurrentTask.current !== startTask ||
      forceRefresh
    ) {
      //All set, initiate comflow content fetch
      refCurrentTask.current = startTask ?? "";
      refIsFetchingComflowContent.current = true;

      setForceRefresh(false);
      setResponse("");

      retrieveComflowContent();
    }
  }, [
    refreshToken,
    comflowLogin,
    currentGoodsOwnerId,
    dispatch,
    forceRefresh,
    hasEmbedded,
    loadComflowContent,
    mygAction,
    retrieveComflowContent,
    showErrorRedirectToHome,
    startTask,
    translate,
  ]);

  const performComflowStyleUpdate = (comflowIframe: HTMLIFrameElement) => {
    //Get the document
    const comflowDocument = comflowIframe.contentWindow?.document;

    const hideById = (elementId: string) => {
      const element = comflowDocument?.getElementById(elementId);

      //If elements exists, add styles
      if (element) {
        element.style.display = "none";
      }
    };

    const hideByClass = (elementClass: string) => {
      const classesToHide = comflowDocument?.querySelectorAll(elementClass);

      classesToHide?.forEach(classToHide => {
        const elementToHide = classToHide as HTMLElement;

        elementToHide.style.display = "none";
      });
    };

    //Elements to hide
    hideById("header");
    hideById("portletTitleBarItemMiddle");
    hideById("portletTitleBarItemRight");
    hideByClass("div.CA-footer");
    hideByClass("div.CA-toolbar");
    hideByClass("div.CA-portletButtonMinimize");
    hideByClass("div.CA-portletButtonMaximize");

    //Elements to style
    const comflowPortalCells = comflowDocument?.querySelectorAll("div.portalcell");

    comflowPortalCells?.forEach(element => {
      const htmlElement = element as HTMLElement;

      htmlElement.style.boxShadow = "none";
    });
  };

  const redirectToRootIfNoContent = (comflowIframe: HTMLIFrameElement) => {
    //Get the document
    const comflowDocument = comflowIframe.contentWindow?.document;

    //comflow root element
    const comflowPortalColumns = comflowDocument?.querySelectorAll("div.CA-portalColumn");

    if (comflowPortalColumns) {
      //find portal columns
      comflowPortalColumns.forEach(comflowPortalColumn => {
        const portalColumn = comflowPortalColumn as HTMLElement;

        //if there is no form, there is no content. Try to find
        const forms = portalColumn.querySelectorAll("form");

        if (forms.length === 0) {
          //No form found, means no comflow portlet, redirect to root
          history.push("/");
        }
      });
    }
  };

  const performScroll = (comflowIframe: HTMLIFrameElement) => {
    if (!comflowIframe.contentWindow) return;

    const anchor = comflowIframe.contentWindow.document.getElementById("mylocAnchor");

    if (anchor) {
      comflowIframe.contentWindow.scrollTo(0, anchor.offsetTop);
    }
  };

  const handleComflowContentIFrame = (action: string) => {
    //Helper method to check if element is iframe
    const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
      input !== null && input.tagName === "IFRAME";

    //The iframe containing Comflow
    const comflowIframe = document.getElementById("ComflowIframe");

    if (isIFrame(comflowIframe) && comflowIframe.contentWindow) {
      //Comflow is embedded as an iframe, handle the content

      if (action === CONTENT_ACTION) {
        redirectToRootIfNoContent(comflowIframe);
        performComflowStyleUpdate(comflowIframe);
      } else if (action === SCROLL_ACTION) {
        performScroll(comflowIframe);
      }
    }
  };

  const createEventListeners = () => {
    //Events are fired from Comflow, defined in ca_main.
    window.addEventListener("message", function (event) {
      const message: ComflowIframeMessage = event.data;

      if (message.source !== EMBEDDED_PAGE) return;

      switch (message.type) {
        case LOADING_COMPLETED:
          if (message.value === true) {
            handleComflowContentIFrame(CONTENT_ACTION);
          }
          break;
        case HIDE_SPINNER_COMPLETED:
          if (message.value === true) {
            handleComflowContentIFrame(SCROLL_ACTION);
          }
          break;
      }
    });
  };

  if (response) {
    //Event listener listening for events from embedded iframe
    createEventListeners();
  }

  return { response, responseReceived, refIsFetching: refIsFetchingComflowContent };
};

useComflow.propTypes = {
  startTask: PropTypes.string,
};

export default useComflow;
