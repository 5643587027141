import { AdditionalRequestState, IdObject } from "../dataTypes";
import { SortedPaginationFilter, SortFilter } from "../utils/pagination";

export const goodsOwnerSliceName = "goodsOwner";

export interface ResponseGoodsOwner extends IdObject {
  name: string;
  inActive: boolean;
  createdDate: string;
}

interface GoodsOwnerSorting extends SortFilter {
  field: "CreatedDate";
}
export interface GoodsOwnersFilter extends SortedPaginationFilter {
  sort?: GoodsOwnerSorting[];
}
export interface GetGoodsOwnersFilter {
  filter?: GoodsOwnersFilter;
}

export interface GoodsOwner extends ResponseGoodsOwner, AdditionalRequestState {}
