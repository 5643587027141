import { ValueOf } from "../../utils/dataTypes";
import { AdditionalRequestState, IdObject, ImportedDataFilter } from "../dataTypes";
import { ResponseGoodsOwner } from "../goodsOwner/dataTypes";

export const userSliceName = "user";

const LOCALES = {
  SV: "sv_SE",
  EN: "en_US",
} as const;

export interface ResponseUser extends IdObject {
  firstName: string;
  surname: string;
  cellPhoneNumbers: string;
  emailAddress: string;
  defaultGoodsOwnerId: string;
  localeId: ValueOf<typeof LOCALES>;
}

export interface UserImportedDataResponse {
  goodsOwners?: ResponseGoodsOwner[];
}

export interface User extends ResponseUser, AdditionalRequestState {}

export interface UserImportedDataFilter {
  defaultGoodsOwner?: ImportedDataFilter;
}
