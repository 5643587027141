import LaunchIcon from "@mui/icons-material/Launch";
import { Box, Link, Tab, Tabs, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router";
import { useLazyGetCustomerOrderPackage } from "../../../features/customerOrderPackage/hooks/useCustomerOrderPackage";
import { translate } from "../../../preferences/localization";
import { DATE_FORMATS } from "../../../utils/DateUtil";
import Page from "../../shared/Page/Page";
import TabPanel from "../../shared/TabPanel/TabPanel";
import Attachments from "./Attachments";
import CarrierEvents from "./CarrierEvents";
import CustomerOrderLinesDataGrid from "./CustomerOrderLinesDataGrid";
import styles from "./CustomerOrderPackageDetailPage.module.scss";

const BANNER_CONTENT_FLEX = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  minHeight: "4.5rem",
  marginTop: "4.5rem",
};

const PAGE_CONTENT_FLEX = { display: "flex", flexDirection: "column", flex: 1, justifyContent: "start" };

//TODO flytta ut till en egen fil
const Label = ({ label, value }: { label: string; value?: string | number }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle1" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1" sx={{ minHeight: "2.1rem" }}>
        {value}
      </Typography>
    </Box>
  );
};

const TAB = {
  ITEMS: 0,
  ATTACHMENTS: 1,
} as const;

const CustomerOrderPackageDetailPage = () => {
  const queryParams = useParams<{ customerOrderPackage: string }>();

  const [tabValue, setTabValue] = useState<number>(TAB.ITEMS);

  const { customerOrderPackage } = useLazyGetCustomerOrderPackage({
    loadIfNotInitialized: true,
    id: queryParams.customerOrderPackage,
  });

  //TODO flytta ut alla sx properties så det slipper att kopieras från alla ställen
  //TODO fundera på om man ska css istället för att ha en bild
  //TODO fundera på att bryta ut bannern till en egen komponent, borde göra men det tar extra tid
  //TODO ändra så att margin-top kommer från sizes
  return (
    <>
      <Box className={styles.banner}>
        <Box className={styles.bannerInnerContainer}>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              alignItems: "top",
              "& > *": {
                margin: "3.4rem 0.8rem",
                flexWrap: "wrap",
                display: "flex",
                width: "35%",
                flexDirection: "column",
                JustifyContent: "center",
              },
            }}
          >
            <Box sx={{ wordWrap: "nowrap" }}>
              <h1>
                {translate("PACKAGE")} #{customerOrderPackage?.sscc}
              </h1>
              {customerOrderPackage?.linkToPackage && (
                <Link className={styles.trackingLink} href={customerOrderPackage.linkToPackage} target="_blank">
                  {translate("TRACK_PACKAGE_WITH_CARRIER")}{" "}
                  <LaunchIcon sx={{ fontSize: "1.7rem", marginLeft: "0.4rem", marginTop: "0.2rem" }} />
                </Link>
              )}
            </Box>
            <Box sx={BANNER_CONTENT_FLEX}>
              <Label label={translate("SHIPMENT_NUMBER")} value={customerOrderPackage?.shipmentNumber} />
            </Box>
            <Box sx={BANNER_CONTENT_FLEX}>
              <Label
                label={translate("ETA_FROM_CARRIER")}
                value={
                  customerOrderPackage?.eta ? dayjs(customerOrderPackage.eta).format(DATE_FORMATS.YYYY_MM_DD_HH_mm) : ""
                }
              />
            </Box>
            <Box sx={BANNER_CONTENT_FLEX}>
              <Label label={translate("LAST_EVENT")} value={customerOrderPackage?.lastEvent} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Page>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2.4rem",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "60%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", "& > *": { margin: "0.8rem" } }}>
              <Box sx={{ width: "49%", ...PAGE_CONTENT_FLEX, "& > *": { margin: "0.8rem" } }}>
                <h3>{translate("RECIPIENT_DETAIL")}</h3>
                <Label label={translate("RECIPIENT_NAME")} value={customerOrderPackage?.recipientName} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle1" component="span" color="textSecondary">
                    {translate("RECIPIENT_ADRESS")}
                  </Typography>
                  <Typography variant="body1" component="span">
                    {customerOrderPackage?.recipientAddress} <br />
                    {customerOrderPackage?.recipientZipCode} - {customerOrderPackage?.recipientCity} <br />
                    {customerOrderPackage?.recipientCountry}
                  </Typography>
                </Box>
                <Label
                  label={translate("RECIPIENT_TELEPHONE_NUMBER")}
                  value={customerOrderPackage?.recipientTelephone}
                />
                <Label label={translate("RECIPIENT_EMAIL")} value={customerOrderPackage?.recipientEmail} />
              </Box>
              <Box sx={{ width: "49%", ...PAGE_CONTENT_FLEX, "& > *": { margin: "0.8rem" } }}>
                <h3>{translate("CARRIER_DETAILS")}</h3>
                <Label label={translate("CARRIER")} value={customerOrderPackage?.carrier} />
                <Label label={translate("CARRIER_PRODUCT")} value={customerOrderPackage?.carrierProduct} />
              </Box>
            </Box>
            <Box sx={{ marginTop: "2.5rem", width: "100%" }}>
              <Tabs
                value={tabValue}
                onChange={(event: React.SyntheticEvent, selectedTabValue: number) => setTabValue(selectedTabValue)}
              >
                <Tab label={translate("ITEMS")} />
                <Tab label={translate("ATTACHMENTS")} />
              </Tabs>

              <TabPanel value={tabValue} index={TAB.ITEMS}>
                <Box sx={{ display: "flex", flexDirection: "column", flex: 2 }}>
                  <CustomerOrderLinesDataGrid packageId={queryParams.customerOrderPackage} />
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={TAB.ATTACHMENTS}>
                <Box sx={{ display: "flex", flexDirection: "column", flex: 2 }}>
                  <Attachments packageId={queryParams.customerOrderPackage} />
                </Box>
              </TabPanel>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "30%" }}>
            <Box sx={{ ...PAGE_CONTENT_FLEX, "& > *": { margin: "0.8rem" } }}>
              <h3>{translate("CARRIER_EVENTS")}</h3>
              <CarrierEvents packageId={queryParams.customerOrderPackage} />
            </Box>
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default CustomerOrderPackageDetailPage;
