import { Button } from "@mui/material";
import { ComponentPropsWithoutRef } from "react";

type MUIButtonProps = ComponentPropsWithoutRef<typeof Button>;

export type ButtonProps = MUIButtonProps & { label: string; isLoading?: boolean };

type ButtonColors = ButtonProps["color"];
type ButtonColor = { [T in NonNullable<ButtonColors> as Uppercase<T>]: T };

type ButtonVariants = ButtonProps["variant"];
type ButtonVariant = { [T in NonNullable<ButtonVariants> as Uppercase<T>]: T };

type ButtonTypes = ButtonProps["type"];
type ButtonType = { [T in NonNullable<ButtonTypes> as Uppercase<T>]: T };

export const BUTTON_TYPE: ButtonType = { BUTTON: "button", SUBMIT: "submit", RESET: "reset" } as const;
export const BUTTON_VARIANT: ButtonVariant = {
  TEXT: "text",
  CONTAINED: "contained",
  OUTLINED: "outlined",
} as const;

export const BUTTON_COLOR: ButtonColor = {
  INHERIT: "inherit",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",
  WARNING: "warning",
} as const;

export type SXProps = NonNullable<MUIButtonProps["sx"]>;
