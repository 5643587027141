import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { GoodsOwner, ResponseGoodsOwner, goodsOwnerSliceName } from "../dataTypes";
import { goodsOwnerAdapter } from "../goodsOwnerAdapter";

const getGoodsOwner = async (dispatch: AppDispatch, { id }: { id: string }) => {
  const url = api.goodsOwners.goodsOwner(id);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, {}, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  goodsOwner: ResponseGoodsOwner;
}

export const fetchGoodsOwner = createAsyncThunk<Response, { id: string }>(
  goodsOwnerSliceName + "/fetchGoodsOwner",
  getGoodsOwner,
);

export const addFetchGoodsOwnerReducers = (builder: EntityActionReducerBuilderInterface<GoodsOwner>) => {
  builder.addCase(fetchGoodsOwner.pending, (state, action) => {
    const { id } = action.meta.arg;
    const goodsOwner = state.entities[id] ?? ({ id } as GoodsOwner);

    goodsOwnerAdapter.upsertOne(state, {
      ...goodsOwner,
      requestState: REQUEST_STATE.PENDING,
      errorMessages: undefined,
    });
  });
  builder.addCase(fetchGoodsOwner.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { id },
      },
    } = action;

    goodsOwnerAdapter.updateOne(state, {
      id,
      changes: mapFulfilledResponseObject(payload.goodsOwner),
    });
  });
  builder.addCase(fetchGoodsOwner.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload == null) return;

    const { id } = meta.arg;

    if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) {
      goodsOwnerAdapter.removeOne(state, id);
    } else {
      goodsOwnerAdapter.updateOne(state, {
        id,
        changes: mapRejectedResponse(payload),
      });
    }
  });
};
