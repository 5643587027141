import { TimelineItem, TimelineOppositeContent } from "@mui/lab";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Card, CardContent } from "@mui/material";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { ValueOf } from "../../../utils/dataTypes";
import { DATE_FORMATS } from "../../../utils/DateUtil";
import { Event } from "./dataTypes";

export default function EventListing({
  events,
  format,
}: {
  events: Event[];
  format: ValueOf<typeof DATE_FORMATS>;
}): ReactElement {
  return (
    <Card sx={{ backgroundColor: "#EFFBFF" }}>
      <CardContent>
        <Timeline>
          {events.map((event, index) => {
            const formattedDate = dayjs(event.dateTime).format(format);

            return (
              <>
                <TimelineItem key={event.id}>
                  <TimelineOppositeContent color="text.secondary">{formattedDate}</TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    {index < events.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>{event.event}</TimelineContent>
                </TimelineItem>
              </>
            );
          })}
        </Timeline>
      </CardContent>
    </Card>
  );
}
