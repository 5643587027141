import { Box, Tab, Tabs, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router";
import { useLazyGetCustomerOrder } from "../../../features/customerOrder/hooks/useCustomerOrder";
import { translate } from "../../../preferences/localization";
import { DATE_FORMATS } from "../../../utils/DateUtil";
import Page from "../../shared/Page/Page";
import TabPanel from "../../shared/TabPanel/TabPanel";
import Attachments from "./AttachMents";
import styles from "./CustomerOrderDetailPage.module.scss";
import CustomerOrderEvents from "./CustomerOrderEvents";
import CustomerOrderLinesDataGrid from "./CustomerOrderLineGrid";
import CustomerOrderPackagesDataGrid from "./CustomerOrderPackagesGrid";

const BANNER_CONTENT_FLEX = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  minHeight: "4.5rem",
  marginTop: "4.5rem",
};

const PAGE_CONTENT_FLEX = { display: "flex", flexDirection: "column", flex: 1, justifyContent: "start" };

//TODO flytta ut till en egen fil
const Label = ({ label, value }: { label: string; value?: string | number }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle1" component="span" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1" component="span" sx={{ minHeight: "2.1rem" }}>
        {value}
      </Typography>
    </Box>
  );
};

const TAB = {
  PACKAGES: 0,
  ORDER_LINES: 1,
  ATTACHMENTS: 2,
} as const;

const CustomerOrderDetailPage = () => {
  const queryParams = useParams<{ customerOrder: string }>();

  const [tabValue, setTabValue] = useState<number>(TAB.PACKAGES);

  const { customerOrder } = useLazyGetCustomerOrder({
    loadIfNotInitialized: true,
    id: queryParams.customerOrder,
  });

  //TODO flytta ut alla sx properties så det slipper att kopieras från alla ställen
  //TODO fundera på om man ska css istället för att ha en bild
  //TODO fundera på att bryta ut bannern till en egen komponent, borde göra men det tar extra tid
  //TODO ändra så att margin-top kommer från sizes
  return (
    <>
      <Box className={styles.banner}>
        <Box className={styles.bannerInnerContainer} sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              alignItems: "top",
              "& > *": {
                margin: "3.4rem 0.8rem",
                flexWrap: "wrap",
                display: "flex",
                width: "35%",
                flexDirection: "column",
                JustifyContent: "center",
              },
            }}
          >
            <Box>
              <h1>Order #{customerOrder?.clientsOrderNumber}</h1>
            </Box>
            <Box sx={{ ...BANNER_CONTENT_FLEX }}>
              <Label
                label={translate("ORDER_DATE")}
                value={dayjs(customerOrder?.orderDate).format(DATE_FORMATS.YYYY_MM_DD_HH_mm)}
              />
            </Box>
            <Box sx={{ ...BANNER_CONTENT_FLEX }}>
              <Label
                label={translate("REQUESTED_DELIVERY_DATE")}
                value={
                  customerOrder?.deliveryDate ? dayjs(customerOrder.deliveryDate).format(DATE_FORMATS.YYYY_MM_DD) : ""
                }
              />
            </Box>
            <Box sx={{ ...BANNER_CONTENT_FLEX }}>
              <Label label={translate("LAST_EVENT")} value={customerOrder?.lastEvent} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Page>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2.4rem",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "60%", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", "& > *": { margin: "0.8rem" } }}>
              <Box sx={{ width: "49%", ...PAGE_CONTENT_FLEX, "& > *": { margin: "0.8rem" } }}>
                <h3>{translate("RECIPIENT_DETAIL")}</h3>
                <Label label={translate("RECIPIENT_NAME")} value={customerOrder?.recipientName} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle1" component="span" color="textSecondary">
                    {translate("RECIPIENT_ADRESS")}
                  </Typography>
                  <Typography variant="body1" component="span">
                    {customerOrder?.recipientAddress} <br />
                    {customerOrder?.recipientZipCode} - {customerOrder?.recipientCity} <br />
                    {customerOrder?.recipientCountry}
                  </Typography>
                </Box>
                <Label label={translate("RECIPIENT_TELEPHONE_NUMBER")} value={customerOrder?.recipientTelephone} />
                <Label label={translate("RECIPIENT_EMAIL")} value={customerOrder?.recipientEmail} />
              </Box>
              <Box sx={{ width: "49%", ...PAGE_CONTENT_FLEX, "& > *": { margin: "0.8rem" } }}>
                <h3>{translate("CARRIER_DETAILS")}</h3>
                <Label label={translate("CARRIER")} value={customerOrder?.carrier} />
                <Label label={translate("CARRIER_PRODUCT")} value={customerOrder?.carrierProduct} />
              </Box>
            </Box>
            <Box sx={{ marginTop: "2.5rem", width: "100%" }}>
              <Tabs
                value={tabValue}
                onChange={(event: React.SyntheticEvent, selectedTabValue: number) => setTabValue(selectedTabValue)}
              >
                <Tab label={translate("ORDER_LINES")} />
                <Tab label={translate("PACKAGES")} />
                <Tab label={translate("ATTACHMENTS")} />
              </Tabs>

              <TabPanel value={tabValue} index={0}>
                <CustomerOrderLinesDataGrid customerOrderId={queryParams.customerOrder} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <CustomerOrderPackagesDataGrid customerOrder={customerOrder} />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Attachments customerOrderId={queryParams.customerOrder} />
              </TabPanel>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", width: "30%" }}>
            <Box sx={{ ...PAGE_CONTENT_FLEX, "& > *": { margin: "0.8rem" } }}>
              <h3>{translate("EVENTS")}</h3>
              <CustomerOrderEvents customerOrderId={queryParams.customerOrder} />
            </Box>
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default CustomerOrderDetailPage;
