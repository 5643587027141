import { GridColDef, GridPaginationModel } from "@mui/x-data-grid-premium";
import { useMemo, useState } from "react";
import { generatePath } from "react-router";
import { CustomerOrder } from "../../../features/customerOrder/dataTypes";
import {
  CUSTOMER_ORDER_PACKAGE_FIELDS,
  CUSTOMER_ORDER_PACKAGE_FILTRATION_FIELDS,
  GetCustomerOrderPackagesFilter,
} from "../../../features/customerOrderPackage/dataTypes";
import useGetCustomerOrderPackages from "../../../features/customerOrderPackage/hooks/useGetCustomerOrderPackages";
import { FULFILLED_UPDATE_METHOD } from "../../../features/dataTypes";
import { BINDER } from "../../../features/utils/SqlBinders";
import { SQL_OPERATOR } from "../../../features/utils/SqlOperators";
import useHistory from "../../../navigation/useHistory";
import { translate } from "../../../preferences/localization";
import { DATE_FORMATS } from "../../../utils/DateUtil";
import pages from "../../../utils/pages";
import DataGrid from "../../DataGrid/DataGrid";
import { DEFAULT_PAGINATION_MODEL } from "../../DataGrid/config/DataGridConfig";
import { getFormatterForDate } from "../../DataGrid/util/DataGridUtil";

const standardColumnDef = {
  editable: false,
  width: 150,
  sortable: false,
};

function CUSTOMER_ORDER_LINE_COLUMNS(): GridColDef[] {
  return [
    {
      field: CUSTOMER_ORDER_PACKAGE_FIELDS.SSCC,
      headerName: translate("PACKAGE_NUMBER"),
      ...standardColumnDef,
    },
    {
      field: CUSTOMER_ORDER_PACKAGE_FIELDS.SHIPMENT_NUMBER,
      headerName: translate("SHIPMENT_NUMBER"),
      ...standardColumnDef,
    },
    {
      field: CUSTOMER_ORDER_PACKAGE_FIELDS.LASTEVENT,
      headerName: translate("LAST_EVENT"),
      ...standardColumnDef,
    },
    {
      field: CUSTOMER_ORDER_PACKAGE_FIELDS.ETA,
      headerName: translate("ETA_FROM_CARRIER"),
      valueFormatter: ({ value }) => getFormatterForDate(value, DATE_FORMATS.YYYY_MM_DD_HH_mm),
      ...standardColumnDef,
    },
  ];
}

const CustomerOrderPackagesDataGrid = ({ customerOrder }: { customerOrder?: CustomerOrder }) => {
  const serverMode = "server";
  const history = useHistory();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);

  const filter = useMemo<GetCustomerOrderPackagesFilter | undefined>(() => {
    if (customerOrder == null) return undefined;

    const customerIds = customerOrder.id.split("¤");

    const paginatedRequestFilter: GetCustomerOrderPackagesFilter = {
      paginationOptions: {
        all: true,
        amount: 0,
        from: 0,
      },
      orderByFields: [],
      whereGroups: [
        {
          binder: BINDER.AND,
          fields: [
            {
              field: CUSTOMER_ORDER_PACKAGE_FILTRATION_FIELDS.CLIENT,
              operator: SQL_OPERATOR.EQ,
              value: customerIds[0] ?? "",
            },
            {
              field: CUSTOMER_ORDER_PACKAGE_FILTRATION_FIELDS.ORDER_NUMBER,
              operator: SQL_OPERATOR.EQ,
              value: customerIds[1] ?? "",
            },
          ],
        },
      ],
    };

    const shipmentNumber = customerOrder.shipmentNumber as undefined | string;

    if (shipmentNumber != null && customerOrder.shipmentNumber !== "") {
      paginatedRequestFilter.whereGroups = [
        {
          binder: BINDER.AND,
          fields: [
            {
              field: CUSTOMER_ORDER_PACKAGE_FILTRATION_FIELDS.SHIPMENT_NUMBER,
              operator: SQL_OPERATOR.EQ,
              value: shipmentNumber,
            },
            ...paginatedRequestFilter.whereGroups.flatMap(whereGroup => whereGroup.fields),
          ],
        },
      ];
    }

    return paginatedRequestFilter;
  }, [customerOrder]);

  const { customerOrderPackages, count, isLoading } = useGetCustomerOrderPackages({
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.UPSERT_MANY,
    filter,
  });

  return (
    <DataGrid
      disableColumnFilter
      autoHeight
      loading={isLoading}
      pagination
      paginationMode={serverMode}
      paginationModel={paginationModel}
      pageSizeOptions={[paginationModel.pageSize]}
      onPaginationModelChange={setPaginationModel}
      rows={customerOrderPackages}
      rowCount={count}
      columns={CUSTOMER_ORDER_LINE_COLUMNS()}
      onRowSelectionModelChange={newRowSelectionModel => {
        const path = generatePath(pages.CUSTOMER_ORDER_PACKAGE_DETAIL.PATH, {
          customerOrderPackage: newRowSelectionModel[0],
        });

        history.push({
          location: {
            pathname: path,
          },
          breadcrumb: { text: translate("PACKAGE_OVERVIEW"), link: path },
        });
      }}
    />
  );
};

export default CustomerOrderPackagesDataGrid;
