import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sessionSliceName, SessionState } from "./dataTypes";
import { addFetchComflowSessionValuesReducers } from "./reducers/fetchComflowSessionValues";
import { addFetchCurrentUserReducers } from "./reducers/fetchCurrentUser";
import { addFetchNextUserAccessMessageReducers } from "./reducers/fetchNextUserAccessMessage";
import { addFetchSessionDataReducers } from "./reducers/fetchSessionData";
import { addLogInReducers } from "./reducers/logIn";
import { addLogOutReducers } from "./reducers/logOut";
import { addUpdateCurrentUserDataReducers } from "./reducers/updateCurrentUserData";
import { addUpdateUserAccessMessageReducers } from "./reducers/updateUserAccessMessage";
import { saveCurrentGoodsOwnerId } from "./utils/saveCurrentGoodsOwnerId";

const initialState: SessionState = {
  isInitialized: false,
};

const slice = createSlice({
  name: sessionSliceName,
  initialState,
  reducers: {
    setIsInitialized(state, action: PayloadAction<boolean>) {
      state.isInitialized = action.payload;
    },
    setRememberMe(state, action: PayloadAction<boolean>) {
      state.rememberMe = action.payload;
    },
    setIsSingleSignOn(state, action: PayloadAction<boolean>) {
      state.isSingleSignOn = action.payload;
    },
    setIsSingleSignOut(state, action: PayloadAction<boolean>) {
      state.singleSignOut = action.payload;
    },
    setLoginToken(state, action: PayloadAction<string>) {
      state.loginToken = action.payload;
    },
    setCurrentGoodsOwnerId(state, action: PayloadAction<string>) {
      const currentGoodsOwnerId = action.payload;

      state.currentGoodsOwnerId = currentGoodsOwnerId;
      saveCurrentGoodsOwnerId(currentGoodsOwnerId);
    },
    setComflowSessionId(state, action: PayloadAction<string>) {
      state.comflowSessionId = action.payload;
    },
    resetComflowSessionId(state) {
      state.comflowSessionId = undefined;
    },
  },
  extraReducers: builder => {
    addFetchComflowSessionValuesReducers(builder);
    addFetchCurrentUserReducers(builder);
    addFetchNextUserAccessMessageReducers(builder);
    addFetchSessionDataReducers(builder);
    addLogInReducers(builder);
    addLogOutReducers(builder);
    addUpdateCurrentUserDataReducers(builder);
    addUpdateUserAccessMessageReducers(builder);
  },
});

const { actions, reducer } = slice;

export const {
  resetComflowSessionId,
  setComflowSessionId,
  setIsInitialized,
  setIsSingleSignOn,
  setIsSingleSignOut,
  setRememberMe,
  setLoginToken,
  setCurrentGoodsOwnerId,
} = actions;

export default reducer;
