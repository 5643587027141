export const CURRENT_GOODS_OWNER_ID = "currentGoodsOwnerId";
export const REMEMBER_ME = "rememberMe";
export const SESSION_ID = "sessionId";
export const LOGGED_IN = "loggedIn";
export const LOGIN_TOKEN = "loginToken";
export const SINGLE_SIGN_ON_RESPONSE = "singleSignOnResponse";
export const CLIENT_SELECTED = "client_selected";
export const COMFLOW_SESSION_ID = "ComflowSessionId";
export const TRACK_AND_TRACE_PACKAGES_GRID_ID = "TRACK_AND_TRACE_CUSTOMER_ORDERS_PACKAGES";
export const TRACK_AND_TRACE_ORDERS_GRID_ID = "TRACK_AND_TRACE_CUSTOMER_ORDERS";

export const LOCAL_STORAGE_KEYS_DO_NOT_CLEAR_ON_LOGOUT = [
  TRACK_AND_TRACE_PACKAGES_GRID_ID,
  TRACK_AND_TRACE_ORDERS_GRID_ID,
];
