import { useMemo } from "react";
import { GetCustomerOrderEventsFilter } from "../../../features/customerOrderEvent/dataTypes";
import useGetCustomerOrderEvents from "../../../features/customerOrderEvent/hooks/useGetCustomerOrderEvents";
import { FULFILLED_UPDATE_METHOD } from "../../../features/dataTypes";
import { DATE_FORMATS } from "../../../utils/DateUtil";
import EventListing from "../../shared/EventListing/EventListing";

const CustomerOrderEvents = ({ customerOrderId }: { customerOrderId: string }) => {
  const filter = useMemo<GetCustomerOrderEventsFilter>(() => {
    return { paginationOptions: { all: true, amount: 0, from: 0 } };
  }, []);

  const { customerOrderEvents } = useGetCustomerOrderEvents({
    filter,
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.UPSERT_MANY,
    customerOrderId: customerOrderId,
  });

  const customerOrderEventsWithDateTime = useMemo(() => {
    return customerOrderEvents.map(event => {
      return {
        ...event,
        dateTime: `${event.date} ${event.time}`,
      };
    });
  }, [customerOrderEvents]);

  return customerOrderEvents.length > 0 ? (
    <EventListing format={DATE_FORMATS.YYYY_MM_DD_HH_mm} events={customerOrderEventsWithDateTime} />
  ) : null;
};

export default CustomerOrderEvents;
