import { Button as MuiButton } from "@mui/material";
import { MutableOnlyArray } from "../../../../utils/dataTypes";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import styles from "./Button.module.scss";
import { ButtonProps, SXProps } from "./dataTypes";

export default function Button({ label, isLoading = false, sx: sxProp, startIcon, ...rest }: ButtonProps) {
  const sx: MutableOnlyArray<SXProps> = [{ width: "inherit", borderRadius: "3000px", textDecoration: "none" }];

  if (Array.isArray(sxProp)) sx.push(...sxProp);
  else if (sxProp) sx.push(sxProp);

  return (
    <MuiButton
      startIcon={
        isLoading ? (
          <LoadingSpinner customCssClass={styles.spinner} customSpinnerCssClass={styles.spinnerImage} />
        ) : (
          startIcon
        )
      }
      {...rest}
      sx={sx}
    >
      {!isLoading && <>{label}</>}
    </MuiButton>
  );
}
