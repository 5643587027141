import { useMemo } from "react";
import useTranslate from "../../../../language/useTranslate";
import useMenu from "../../../../utils/menu/useMenu";
import pages from "../../../../utils/pages";
import useAuthorization from "../../../../utils/useAuthorizations";
import PnTileMyloc from "../../../shared/PostNord/Tile/PnTileMyloc";
import QUICK_ACCESS_ITEMS from "./quickAccessItems";
import styles from "./QuickAccessWelcome.module.scss";

export default function QuickAccessWelcome() {
  const translate = useTranslate();
  const { isAdmin, isAuthorizedToTask, isAuthorizedToFunction } = useAuthorization();

  const filteredQuickAccessItems = useMemo(() => {
    if (isAdmin) return QUICK_ACCESS_ITEMS;
    return QUICK_ACCESS_ITEMS.filter(item => {
      if (item.comflowTaskId) return isAuthorizedToTask(item.comflowTaskId);
      else if (item.functionId) return isAuthorizedToFunction(item.functionId);
      return false;
    });
  }, [isAdmin, isAuthorizedToTask, isAuthorizedToFunction]);

  const menu = useMenu("MENU");

  const showReports = menu.some(item => item.id === pages.REPORTS.PATH);

  return (
    <div className={styles.pnTiles}>
      <h2>{translate("QUICK_ACCESS")}</h2>
      <div className={styles.pnTileGrid}>
        {filteredQuickAccessItems.map(quickAccessItem => (
          <PnTileMyloc
            key={quickAccessItem.label}
            label={translate(quickAccessItem.label)}
            illustration={quickAccessItem.illustration}
            content={translate(quickAccessItem.content)}
            link={quickAccessItem.link}
          />
        ))}
        {showReports ? (
          <PnTileMyloc
            label={translate(pages.REPORTS.NAME)}
            illustration={"letter-graphics"}
            content={translate("MANAGE_REPORTS")}
            link={pages.REPORTS.PATH}
          />
        ) : null}
      </div>
    </div>
  );
}
