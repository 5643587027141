import { combineReducers } from "@reduxjs/toolkit";
import comflowSessionValuesSlice from "../features/comflowSessionValues/comflowSessionValuesSlice";
import { comflowSessionValuesSliceName } from "../features/comflowSessionValues/dataTypes";
import customerOrderSlice from "../features/customerOrder/customerOrderSlice";
import { customerOrderSliceName } from "../features/customerOrder/dataTypes";
import customerOrderEventSlice from "../features/customerOrderEvent/customerOrderEventSlice";
import { customerOrderEventSliceName } from "../features/customerOrderEvent/dataTypes";
import customerOrderFileSlice from "../features/customerOrderFile/customerOrderFileSlice";
import { customerOrderFileSliceName } from "../features/customerOrderFile/dataTypes";
import customerOrderLastEventSlice from "../features/customerOrderLastEvent/customerOrderLastEventSlice";
import { customerOrderLastEventSliceName } from "../features/customerOrderLastEvent/dataTypes";
import customerOrderLineSlice from "../features/customerOrderLine/customerOrderLineSlice";
import { customerOrderLineSliceName } from "../features/customerOrderLine/dataTypes";
import { default as customerOrderPackageSlice } from "../features/customerOrderPackage/customerOrderPackageSlice";
import { customerOrderPackageSliceName } from "../features/customerOrderPackage/dataTypes";
import customerOrderPackageCarrierEventSlice from "../features/customerOrderPackageCarrierEvent/customerOrderPackageCarrierEventSlice";
import { customerOrderPackageCarrierEventSliceName } from "../features/customerOrderPackageCarrierEvent/dataTypes";
import customerOrderPackageFileSlice from "../features/customerOrderPackageFile/customerOrderPackageFileSlice";
import { customerOrderPackageFileSliceName } from "../features/customerOrderPackageFile/dataTypes";
import customerOrderPackageLastEventsSlice from "../features/customerOrderPackageLastEvent/customerOrderPackageLastEventSlice";
import { customerOrderPackageLastEventSliceName } from "../features/customerOrderPackageLastEvent/dataTypes";
import { goodsOwnerSliceName } from "../features/goodsOwner/dataTypes";
import goodsOwnerSlice from "../features/goodsOwner/goodsOwnerSlice";
import { isakRedirectUriSliceName } from "../features/isak/dataTypes";
import isakRedirectUriSlice from "../features/isak/isakSlice";
import { passwordRulesSliceName } from "../features/passwordRules/dataTypes";
import passwordRulesSlice from "../features/passwordRules/passwordRulesSlice";
import { regionSliceName } from "../features/region/dataTypes";
import regionSlice from "../features/region/regionSlice";
import systemSettingsSlice from "../features/system/systemSlice";
import { userSliceName } from "../features/user/dataTypes";
import userSlice from "../features/user/userSlice";
import { userAccessMessageSliceName } from "../features/userAccessMessage/dataTypes";
import userAccessMessageSlice from "../features/userAccessMessage/userAccessMessageSlice";
import { weekLetterSliceName } from "../features/weekLetter/dataTypes";
import weekLetterSlice from "../features/weekLetter/weekLetterSlice";
import { systemSettingsSliceName } from "./../features/system/dataTypes";

const appDataReducer = combineReducers({
  [comflowSessionValuesSliceName]: comflowSessionValuesSlice,
  [goodsOwnerSliceName]: goodsOwnerSlice,
  [regionSliceName]: regionSlice,
  [userAccessMessageSliceName]: userAccessMessageSlice,
  [userSliceName]: userSlice,
  [weekLetterSliceName]: weekLetterSlice,
  [passwordRulesSliceName]: passwordRulesSlice,
  [customerOrderSliceName]: customerOrderSlice,
  [customerOrderLastEventSliceName]: customerOrderLastEventSlice,
  [isakRedirectUriSliceName]: isakRedirectUriSlice,
  [customerOrderPackageSliceName]: customerOrderPackageSlice,
  [customerOrderPackageCarrierEventSliceName]: customerOrderPackageCarrierEventSlice,
  [customerOrderPackageLastEventSliceName]: customerOrderPackageLastEventsSlice,
  [customerOrderPackageFileSliceName]: customerOrderPackageFileSlice,
  [customerOrderEventSliceName]: customerOrderEventSlice,
  [customerOrderFileSliceName]: customerOrderFileSlice,
  [customerOrderLineSliceName]: customerOrderLineSlice,
  [systemSettingsSliceName]: systemSettingsSlice,
});

export default appDataReducer;
